import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
//import logo from "content/images/logo.png"


const StyledLogo = styled.img`
  position: relative;
  z-index: 10;

  height: 4rem;

  /* Disable effects when sidebar is open */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
`

const Logo = ({ size, img }) => (
  <StyledLogo src={img} />
)

Logo.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Logo
