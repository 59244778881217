import { createGlobalStyle } from "styled-components"
import modernNormalize from "styled-modern-normalize"
import basierCircle from "../fonts/basiercircle-regular.woff"
const GlobalStyle = createGlobalStyle`
    
  // Import normalize.css
  ${modernNormalize}
  @font-face {
    font-family: basier-circle;
    src: url(${basierCircle}) format("woff");
  }

  @media (min-width:800px) {
    :root {
      font-size: 12px
    }
  }

  @media (min-width:1200px) {
    :root {
      font-size: 11.25px
    }
  }

  @media (min-width:1300px) {
    :root {
      font-size: 12.2px
    }
  }

  @media (min-width:1400px) {
    :root {
      font-size: 13.1px
    }
  }

  @media (min-width:1500px) {
    :root {
      font-size: 13.72px
    }
  }

  @media (min-width:1600px) {
    :root {
      font-size: 15px
    }
  }

  @media (min-width:1700px) {
    :root {
      font-size: 16px
    }
  }

  @media (min-width:1800px) {
    :root {
      font-size: 16.87px
    }
  }

  @media (min-width:1900px) {
    :root {
      font-size: 18px
    }
  }

  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
  }
  
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    line-height: 1.5rem;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    &.blur {
        overflow: hidden;
        #___gatsby #main-content > * {
          filter: blur(0.2941rem) ;
          transition: all .3s ease-out;
          pointer-events: none;
          user-select: none;
        }
      }
    }
    &.splashScreen {
          position: fixed;
          overflow: hidden;
    }
  }

  
  body.search-open {
    overflow: hidden;
    position: fixed;
    header {
      z-index: 0;
    }
  }

  .fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
  }
  
  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: 0;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
    color: ${({ theme }) => theme.colors.primary};
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 2.625rem;
        line-height: 4rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.primary};
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 2rem;
        line-height: 3rem;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 3rem;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  hr {
    margin: 3rem auto;
    border-width: .05rem;
    color: ${({ theme }) => theme.colors.tertiary};
    opacity: 0.1;
  }
  .section-title {
    font-weight: 600;
    font-size: 1.646rem;
    line-height: 2.11752rem;
  }
  .section-subtitle {
    font-size: 0.8823rem;
    color: #C2CAD9;
  }
  .text {
    font-size: 1rem;
    line-height: 0.8823rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .section-title {
      font-weight: 600;
      font-size: 2.29398rem;
      line-height: 2.88218rem;
    }
    .section-subtitle {
      font-size: 1.06rem;
      line-height: 1.646rem;
    }
    .text {
      font-size: 1rem;
      line-height: 1.1764rem;
    }
  }

  .text-center {
    text-align: center;
  }
  
  .mr-05 {
    margin-right: 0.5rem;
  }
  // style checkbox

  .fancy-checkbox {
    padding-left: 1.55rem!important;
    margin-bottom: 0.5882rem;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
  }
  
  .fancy-checkbox__label {
    line-height: 1rem;
    a {
        color: inherit;
    }
  }

  // tab style

  .tabs {
    margin-top: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-top: -3.5rem;
    }
  }
  
  .tab-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    .tab-list-item {
      border: 1px solid ${({ theme }) => theme.colors.border};
      border-bottom: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      cursor: pointer;
      padding: 1rem 1.23522rem;
      background: ${({ theme }) => theme.colors.tertiary};
      color: ${({ theme }) => theme.colors.subtext};
      box-sizing: border-box;
      border-radius: 0.52938rem 0.52938rem 0px 0px;
      font-size: 1rem;
      line-height: 1.9rem;
      margin-right: 0.23528rem;
      span {
        font-size: 1rem;
        line-height: 1.1764rem;
      }
    }
    .tab-list-active {
      color: white;
      background: ${({ theme }) => theme.colors.background};
      position: relative;
      &::before {
        transition: 200ms ease-out;
        height: 1px;
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.colors.background};
        width: 100%;
        bottom: -1px;
      }
    }
  }
  .search-box-toggler {
    position: fixed;
    left: calc(50% - 2.765rem);
    bottom: 1.2rem;
    padding: 0.75rem 1rem;
    border-radius: 2.5rem;
    color: white;
    cursor: pointer;
    transform-origin: center center;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    border-radius: 50px;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    img {
      margin-right: 0.5rem;
      width: 1.1rem;
      height: auto;
    }
  }

  // box boder animation

  .duplication-alert {
    
    --border-angle: 2turn;
    --main-bg: conic-gradient(from var(--border-angle), rgb(255, 244, 229), rgb(255, 244, 229) 76%, rgb(255, 244, 229) 60%, rgb(255, 244, 229) 34%);
    border: solid 2px transparent;
    border-radius: 0.5rem;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #ff71007d, #84a48a 63%, transparent);    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    -webkit-animation: bg-spin 3s linear infinite;
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    &:hover {
      animation-play-state: paused;
    }
  }
  
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  
`

export default GlobalStyle
