import React from "react"
import styled from "styled-components"



const StyledFooter = styled.div`
  max-width: 100%;
  padding-left: 1rem;
  z-index: 10;
  height: 5rem;
  display: flex;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 4.5rem;
  }
`

const StyledLink = styled.div`
  font-size: 0.8823rem;
  line-height: 1.29404rem;
  color: #B4B4B4;
`

const Footer = () => {
  return (
    <StyledFooter>
      <StyledLink>
        Powered by <a href="http://hrflow.ai">HrFlow.ai</a>
      </StyledLink>
    </StyledFooter>
  )
}

export default Footer
