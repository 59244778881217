import { useEffect, useContext } from "react"

import Context from "../context"
import { lightModeActivated } from "../../config"

const useDarkMode = () => {
  const { state, setState } = useContext(Context)
  useEffect(() => {
    if(lightModeActivated) {
      setState({ ...state, darkMode: false })
    } else {
      setState({ ...state, darkMode: true })
    }
  }, [])
/*   useEffect(() => {
    const darkSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)")

    const toggleTheme = event => {
      if (event.matches) {
        setState({ ...state, darkMode: false })
      } else {
        setState({ ...state, darkMode: false })
      }
    }

    if (darkSchemeQuery.matches) {
      setState({ ...state, darkMode: false })
    }

    if (darkSchemeQuery.addEventListener) {
      darkSchemeQuery.addEventListener("change", toggleTheme)
      return () => {
        darkSchemeQuery.removeEventListener("change", toggleTheme)
      }
    } else {
      darkSchemeQuery.addListener(toggleTheme)
      return () => {
        darkSchemeQuery.removeListener(toggleTheme)
      }
    }

  }, []) */

  return state.darkMode
}

export default useDarkMode
