import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import { navLinks } from "../../config"

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F9FAFC;
  height: 100vh;
  width: 80%;
  text-align: left;
  padding: 2rem;
  position: relative;
  right: 0;
  margin-left: auto;
  a {
    color: #84A2B7;
  }
  .nav-link {
    font-size: 0.82348rem;
    font-weight: 600;
    text-align: center;
    padding: 1.5rem 0;
    color: #84A2B7;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: white;
  }
  .cta-btn {
    width: auto;
    height: auto;
    margin: 1.5rem auto;
    padding: 1rem 1.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    color: #84A2B7;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: white;
  }
`

const StyledButton = styled(Link)`
  background: rgba(21, 37, 52, 0.56);
  border: none;
  box-sizing: border-box;
  border-radius: 0.5882rem;
  color: white;
  font-size: 0.94112rem;
  line-height: 1.1764rem;
  text-align: center;
  width: 100%;
  padding: 1.1764rem;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.1s linear;
  margin-bottom: 1rem;
  &:hover {
    filter: grayscale(50%) contrast(1) brightness(90%);
    transform: translate3d(0px, -0.1rem, 0px);
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
  }
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
    background: rgba(0, 0, 0, 0.53);
    border: 1px solid rgba(208, 208, 208, 0.29);
  }
`

const Sidebar = ({ open, setOpen }) => {
  const { menu, button } = navLinks
  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          {menu.map(({ name, url }, key) => (
            <StyledButton
              className="nav-link"
              key={key}
              to={url}
              onClick={() => setOpen(!open)}
            >
              {name}
            </StyledButton>
          ))}
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} />
    </>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default Sidebar
